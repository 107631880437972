import Swiper from "swiper/swiper-bundle";

// Horizontal scroll
const horizontalScroll = new Swiper(".h-scroll", {
  mousewheel: {
    forceToAxis: true,
  },
  slidesOffsetBefore: 0,
  slidesOffsetAfter: 0,
  slidesPerView: 1,
  spaceBetween: 16,
  breakpoints: {
    640: {
      slidesOffsetBefore: 32,
      slidesOffsetAfter: 32,
      slidesPerView: 1.2,
      spaceBetween: 32,
    },
    900: {
      slidesOffsetBefore: 64,
      slidesOffsetAfter: 64,
      slidesPerView: 2.4,
      spaceBetween: 48,
    },
  },
});

// Horizontal scroll 2
const textHorizontalScroll = new Swiper(".text-h-scroll", {
  mousewheel: {
    forceToAxis: true,
  },
  slidesPerView: "auto",
  spaceBetween: 16,
  slidesOffsetBefore: 16,
  slidesOffsetAfter: 16,
  breakpoints: {
    640: {
      slidesOffsetBefore: 32,
      slidesOffsetAfter: 32,
    },
    768: {
      spaceBetween: 32,
      slidesOffsetBefore: 64,
      slidesOffsetAfter: 64,
    },
    900: {
      spaceBetween: 48,
      slidesOffsetBefore: 64,
      slidesOffsetAfter: 64,
    },
  },
});

// Gallery
const galleryThumbs = new Swiper(".gallery-thumbs", {
  spaceBetween: 16,
  slidesPerView: "auto",
  watchSlidesProgress: true,
});

const galleryBody = new Swiper(".gallery-body", {
  spaceBetween: 16,
  enabled: false,
});

const galleryImage = new Swiper(".gallery-image", {
  spaceBetween: 16,
  thumbs: {
    swiper: galleryThumbs,
  },
  controller: {
    control: galleryBody,
  },
});

// Pricing
const pricingThumbs = new Swiper(".pricing-thumbs", {
  spaceBetween: 16,
  slidesPerView: "auto",
  watchSlidesProgress: true,
});

const pricingBody = new Swiper(".pricing-body", {
  spaceBetween: 16,
  enabled: false,
});

const pricingImage = new Swiper(".pricing-image", {
  spaceBetween: 16,
  thumbs: {
    swiper: pricingThumbs,
  },
  controller: {
    control: pricingBody,
  },
});

export {
  horizontalScroll,
  textHorizontalScroll,
  galleryBody,
  galleryImage,
  pricingBody,
  pricingImage,
};
