import { openNav, closeNav } from "./modules/navigation.js";
import * as swiper from "./modules/carousels.js";
import initObserver from "./modules/observer.js";

// Stop transitions before page has loaded
$(window).on("load", function () {
  $("body").removeClass("preload");
});

// Toggle navigation
$("#hamburger").click(function (e) {
  e.preventDefault();
  $("#main-navigation").hasClass("-translate-y-full") ? openNav() : closeNav();
});

// Reset if browser is resized and nav is open
$(window).resize(function () {
  if (
    $(window).width() >= 900 &&
    $("#main-navigation").hasClass("transition")
  ) {
    closeNav();
  }
});

$(".accordion").click(function () {
  $(this).next(".richtext").slideToggle();
  $(this).find("svg").toggleClass("hidden");
});
