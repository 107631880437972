export function openNav() {
  $("#main-navigation")
    .addClass("transition duration-300")
    .removeClass("-translate-y-full");
  $("#hamburger .open").addClass("hidden");
  $("#hamburger .close").removeClass("hidden");

  // Wait for nav to open before opening modal
  setTimeout(function () {
    openModal();
  }, 300);
}

export function closeNav() {
  $("#main-navigation")
    .addClass("-translate-y-full")
    .removeClass("transition duration-300");
  $("#hamburger .close").addClass("hidden");
  $("#hamburger .open").removeClass("hidden");
  closeModal();
}

// Block scrolling
window.addEventListener("scroll", () => {
  document.documentElement.style.setProperty(
    "--scroll-y",
    `${window.scrollY}px`
  );
});

const body = document.body;

function openModal() {
  const scrollY = document.documentElement.style.getPropertyValue("--scroll-y");
  body.style.position = "fixed";
  body.style.top = `-${scrollY}`;
}

function closeModal() {
  const scrollY = body.style.top;
  body.style.position = "";
  body.style.top = "";
  window.scrollTo(0, parseInt(scrollY || "0") * -1);
}
